import { 
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

import exampleReducers from './example/reducers'
import currentUser from './currentUser/reducers'
import leadsChart from './leadsChart/reducers'
import leadsCount from './leadsCount/reducers'
import leads from './leads/reducers'

const reducers = combineReducers({
  charas: exampleReducers,
  currentUser,
  leadsChart,
  leadsCount,
  leads,
})

const store = createStore(
  reducers,/* preloadedState, */
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
)

export default store