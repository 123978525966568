import { GET_LEADS_CHART } from './actionTypes'

const initialState = () => ({
  data: {
    major: [],
    grade: [],
    province: [],
  },
  status: 'idle',
})

const reducers = (state={ ...initialState() }, action) => {
  switch (action.type) {
    case GET_LEADS_CHART.pending:
      return ({
        ...state,
        status: 'pending',
      })
    case GET_LEADS_CHART.success:
      return ({
        ...state,
        data: action.payload,
        status: 'succeed',
      })
    case GET_LEADS_CHART.empty:
      return ({
        ...state,
        status: 'succeed',
      })
    case GET_LEADS_CHART.failed:
      return ({
        ...state,
        status: 'failed',
      })
    default:
      return state
  }
}

export default reducers
