import { lazy } from 'react'

const routes = [
  {
    path: '/signin',
    component: lazy(() => import('Pages/Login')),
    exact: true,
  },
  {
    path: '/profile',
    component: lazy(() => import('Pages/Profile')),
    protected: true,
    exact: true,
  },
  {
    path: '/okey',
    component: lazy(() => import('Pages/Okey')),
    exact: true,
  },
  {
    path: '/',
    component: lazy(() => import('Pages/Home')),
    protected: true,
    exact: true,
  },
]

export default routes
