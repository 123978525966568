import React from 'react'
import ripple from './ripple.svg'

const LoadingIndicator = () => (
  <div className="loading-indicator">
    <img src={ripple} alt="Loading . . ."/>
    <style jsx>{`
      .loading-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;
        img {
          max-width: 100vw;
        }
      }
    `}</style>
  </div>
)

export default LoadingIndicator
