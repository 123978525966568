import { GET_LEADS } from './actionTypes'

const initialState = () => ({
  data: [],
  status: 'idle',
  total: 0,
})

const reducers = (state={ ...initialState() }, action) => {
  switch (action.type) {
    case GET_LEADS.pending:
      return ({
        ...state,
        status: 'pending',
      })
    case GET_LEADS.success:
      return ({
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        status: 'succeed',
      })
    case GET_LEADS.empty:
      return ({
        ...state,
        status: 'succeed',
      })
    case GET_LEADS.failed:
      return ({
        ...state,
        status: 'failed',
      })
    default:
      return state
  }
}

export default reducers
