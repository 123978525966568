import { GET_LEADS_COUNT } from './actionTypes'

const initialState = () => ({
  data: {
    total: 0,
    leads: 0,
    favourite: 0,
    aptitude: 0,
  },
  type: 'total',
  status: 'idle',
})

const reducers = (state={ ...initialState() }, action) => {
  switch (action.type) {
    case GET_LEADS_COUNT.pending:
      return ({
        ...state,
        status: 'pending',
      })
    case GET_LEADS_COUNT.success:
      return ({
        ...state,
        data: action.payload.leads,
        type: action.payload.type,
        status: 'succeed',
      })
    case GET_LEADS_COUNT.empty:
      return ({
        ...state,
        status: 'succeed',
      })
    case GET_LEADS_COUNT.failed:
      return ({
        ...state,
        status: 'failed',
      })
    default:
      return state
  }
}

export default reducers
