import React, { useRef, useEffect } from 'react'
import { useRouteMatch, useHistory, Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import profileType from 'Utils/proptypes/profile'

import Container from 'Components/layout/atoms/Container'

import API from 'Utils/API'

import QCLogo from 'Assets/images/qcp-logo@2x.png'
import AccessToken from 'Utils/AccessToken'

const Navbar = ({ profile }) => {
  const isMounted = useRef(false)
  const history = useHistory()
  const isLoginPage = useRouteMatch('/signin')

  const signOut = e => {
    e && e.preventDefault()

    // clear the listener for reset time purpose
    window.onload = null
    document.onmousemove = null
    document.onkeypress = null

    const accessToken = AccessToken.get()
    if (accessToken) {
      ;(async () => {
        try {
          await API.get('auth/signout', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).json()
          AccessToken.remove()
          history.replace('/signin')
          addEventGa('Logout')
        } catch (error) {
          console.error('Failed to logout', error)
        }
      })()
    }
  }

  const addEventGa = (label) => (
    ReactGA.event({
      category: 'Account',
      action: 'Navigation',
      label: `${profile.username}, ${label}`,
    })
  )

  useEffect(() => {
    if (!isLoginPage && !isMounted.current) {
      let time
      window.onload = resetTimer
      // DOM Events
      document.onmousemove = resetTimer
      document.onkeypress = resetTimer

      function resetTimer() {
        clearTimeout(time)
        time = setTimeout(signOut, 1000 * 60 * 30)
        // 1000 milliseconds = 1 second
      }
    }
  })

  if (!isLoginPage) {
    return (
      <div className="navbar">
        <Container>
          <div className="navbar__inner-container">
            <img className="navbar__logo" src={QCLogo} alt="Quipper Campus Logo"/>

            <nav className="navbar__links">
              <div className="navbar__link-item">
                <Link to="/" onClick={() => addEventGa('Home')}>Home</Link>
              </div>
              <div className="navbar__link-item">
                <Link to="/profile" onClick={() => addEventGa('Profile')}>Profile</Link>
              </div>
              <div className="navbar__link-item">
                <a href="#" onClick={signOut}>Logout</a>
              </div>
            </nav>
          </div>
        </Container>
        <style jsx>{`
          .navbar {
            padding: .25rem 0;
            border-top: solid .25rem #2440a4;
            border-image: linear-gradient(to right, #00BEFF, #0FC3C4) 1 0 0 !important;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
            
            &__inner-container {
              display: flex;
              justify-content: space-between;
            }
            &__links {
              display: flex;
              align-items: center;
              column-gap: 24px;
            }
            &__link-item {
              display: inline-block;
              :global(a) {
                color: #6b8fbc;
                font-weight: bolder;
                text-decoration: none;
                &:hover {
                  color: #009feb;
                }
                @media screen and (max-width: 767px) {
                  font-size: 14px;
                }
              }
            }
            &__logo {
              padding: 1rem 0;
              width: 275px;
              @media screen and (max-width: 767px) {
                width: 130px;
                margin-right: 24px;
              }
            }
          }
        `}</style>
      </div>
    )
  }
  return null
}

Navbar.propTypes = {
  profile: profileType,
}

const mapStateToProps = state => ({
  profile: state.currentUser.data,
})

export default connect(mapStateToProps)(Navbar)
