import LOAD_CURRENT_USER from './actionTypes'

import API from 'Utils/API'
import AccessToken from 'Utils/AccessToken'

export const loadCurrentUser = () => {
  return dispatch => {
    // here async process
    dispatch(loadCurrentUserPending())
    ;(async () => {
      const accessToken = AccessToken.get()
      if (accessToken) {
        try {
          const {profile} = await API.get('profile', {
            headers: {
              Authorization: `Bearer ${AccessToken.get()}`,
            },
          }).json()
          const demoAccount = {
            ...profile,
            id: 'demoAcc',
            type: 'free',
            expDate: '2021-12-31T00:00:00.000Z',
            school: {
              id: 'demo',
              slug: '',
              name: 'Quipper Campus University',
              logo: process.env.PUBLIC_URL + 'qc_small_logo.png',
            },
          }
          const profileData = profile.username === 'qc-officer' ? demoAccount : profile
          dispatch(loadCurrentUserSuccess(profileData))
        }
        catch(err) {
          dispatch(loadCurrentUserFailed({
            status: err.response.status,
            message: err.response.statusText,
          }))
        }
      }
    })()
  }
}

const loadCurrentUserPending = () => ({
  type: LOAD_CURRENT_USER.pending,
})

const loadCurrentUserFailed = err => ({
  type: LOAD_CURRENT_USER.failed,
  payload: err,
})

const loadCurrentUserSuccess = payload => ({
  type: LOAD_CURRENT_USER.success,
  payload,
})

export const resetCurrentUser = payload => ({
  type: LOAD_CURRENT_USER.reset,
  payload,
})
