import React from 'react'

import classnames from 'classnames'

import { bool, string } from 'prop-types'

const Container = ({ fluid, subcontainer, mobileFull, className, children }) => {
  const classNameVal = classnames('cmps-container', className, {
    'cmps-container--fluid': fluid,
    'cmps-container--sub': subcontainer,
    'cmps-container--mobile-full': mobileFull,
  })

  return (
    <div className={classNameVal}>
      {children}
      <style jsx>
        {`
          .cmps-container {
            margin-left: auto;
            margin-right: auto;
            max-width: 340px;
            &--mobile-full {
              max-width: 100%;
              padding: 0;
            }
            @media screen and (min-width: 576px) {
              max-width: 544px;
            }
            @media screen and (min-width: 768px) {
              max-width: 720px;
            }
            @media screen and (min-width: 992px) {
              max-width: 1190px;
            }
            &--fluid {
              max-width: none;
            }
          }
        `}
      </style>
    </div>
  )
}

Container.propTypes = {
  fluid: bool,
  subcontainer: bool,
  mobileFull: bool,
  className: string,
}

Container.defaultProps = {
  fluid: false,
  subcontainer: false,
  mobileFull: false,
}

export default Container
