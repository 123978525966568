import React, { Suspense } from 'react'
import { string, elementType, arrayOf, shape, object } from 'prop-types'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import ProtectedRoute from './ProtectedRoute'

import Loading from 'Components/common/atoms/LoadingIndicator'
import Navbar from 'Components/layout/organisms/Navbar'

import GAPageView from 'Utils/GAPageview'

import routes from './routes'

const AppRoot = () => {
  GAPageView()
  return (
    <>
      <Navbar />
      <Suspense fallback={<Loading />}>
        <Switch>
          {
            routes.map(Page => {
              const RouteWrapper = Page.protected ? ProtectedRoute : Route
              return (
                <RouteWrapper path={Page.path} exact={Page.exact} key={`site-route-${Page.path}`}>
                  <Page.component {...Page.otherProps}/>
                </RouteWrapper>
              )
            })
          }
        </Switch>
      </Suspense>
    </>
  )
}

const SiteRoot = () => {
  return (
    <Router>
      <AppRoot />
    </Router>
  )
}

const pagePropType = shape({
  path: string,
  component: elementType,
  otherProps: object,
})

SiteRoot.propTypes = {
  routesList: arrayOf(pagePropType),
}

export default SiteRoot
