import Cookies from 'js-cookie'

const tokenCookieKey = 'dashboard_api_token'

export const set = (token) => {
  Cookies.set(tokenCookieKey, token)
}

export const get = () => {
  return Cookies.get(tokenCookieKey)
}

export const remove = () => {
  Cookies.remove(tokenCookieKey)
}

export default {
  set,
  get,
  remove,
}