import React from 'react'

import SiteRoot from 'Routes'

import { Provider } from 'react-redux'
import store from 'Store'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-112768068-2', { testMode: process.env.NODE_ENV !== 'production' })

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <SiteRoot />
      </div>
    </Provider>
  )
}

export default App
