import { shape, string } from 'prop-types'

const profileType = shape({
  id: string,
  username: string,
  email: string,
  picName: string,
  phoneNumber: string,
  expDate: string,
  type: string,
  startDateFilter: string,
  endDateFilter: string,
})

export default profileType
