import LOAD_CURRENT_USER from './actionTypes'

const initialState = () => ({
  data: {
    id: '',
    username: '',
    picName: '',
    phoneNumber: '',
    expDate: '',
    type: '',
    school: {
      id: '',
      name: '',
      slug: '',
      expDate: '',
    },
  },
  status: 'idle',
  error: {
    status: false,
    message: '',
  },
})

const reducers = (state={ ...initialState() }, action) => {
  switch (action.type) {
    case LOAD_CURRENT_USER.pending:
      return ({
        ...state,
        status: 'pending',
      })
    case LOAD_CURRENT_USER.success:
      return ({
        ...state,
        data: action.payload,
        status: 'succeed',
      })
    case LOAD_CURRENT_USER.failed:
      return ({
        ...state,
        status: 'failed',
        error: {
          status: action.payload.status,
          message: action.payload.message,
        },
      })
    case LOAD_CURRENT_USER.reset:
      return ({
        ...initialState(),
      })
    default:
      return state
  }
}

export default reducers
